export default {
  services:{
    title_long:{
      english:'Services',
      french:'Services'
    },
    title_short:{
      english:'Services',
      french:'Services'
    },
    icon:'global_services.png',
    submenu:{
      audit_security:{
        title_long:{
          english:'Security Audits',
          french:'Audits de sécurité'
        },
        title_short:{
          english:'Security Audits',
          french:'Audits de sécurité'
        },
        url:'https://sysdream.com/offre/audit-de-securite-informatique/#architecture',
        target:'sysdream',
        icon:'security_audit.png',
      },
      audit_security_passi:{
        title_long:{
          english:'PASSI Audits',
          french:'Audits PASSI'
        },
        title_short:{
          english:'PASSI Audits',
          french:'Audits PASSI'
        },
        url:'https://sysdream.com/offre/test-dintrusion/',
        target:'sysdream',
        icon:'security_audit_passi.png',
      },
      conformity:{
        title_long:{
          english:'Risk & Conformity',
          french:'Risque & Conformité'
        },
        title_short:{
          english:'Risk & Conformity',
          french:'Risque & Conformité'
        },
        url:'https://sysdream.com/offre/grc-cybersecurite-gouvernance-risque-conformite/#conforme',
        target:'sysdream',
        icon:'conformity.png',
      },
      training:{
        title_long:{
          english:'Trainings',
          french:'Formations'
        },
        title_short:{
          english:'Trainings',
          french:'Formations'
        },
        url:'https://sysdream.com/formation-catalogue/',
        target:'sysdream',
        icon:'training.png',
      },
      malice:{
        title_long:{
          english:'Cyber exercise',
          french:'Cyber entrainement'
        },
        title_short:{
          english:'Cyber exercise',
          french:'Cyber entrainement'
        },
        url:'https://sysdream.com/offre/challenge-cft-by-malice/',
        target:'sysdream',
        icon:'malice.png',
      }
    },
  },
  cti:{
    title_long:{
      english:'Incidents',
      french:'Incidents'
    },
    title_short:{
      english:'IPC',
      french:'CTI'
    },
    icon:'soc.png',
    css:'incidents',
    url:'/public/cti',
  },
  tools:{
    icon:'ressources.png',
    title_long:{
      english:'Tools',
      french:'Outils'
    },
    title_short:{
      english:'Tools',
      french:'Outils'
    },
    submenu:{
      files_analysis:{
        title_long:{
          english:'Suspicious files analysis',
          french:'Analyse de fichiers suspicieux'
        },
        title_short:{
          english:'Files analysis',
          french:'Analyse de fichiers'
        },
        url:'https://helix01fr.sysdream.io/analyse',
        target:'analyse',
        icon:'files.png',
      }
    },
  },
  publications:{
    title_long:{
      english:'Papers',
      french:'Publications'
    },
    title_short:{
      english:'Papers',
      french:'Publications'
    },
    icon:'blog.png',
    submenu:{
      cve:{
        title_long:{
          english:'Vulnerabilities encyclopedia',
          french:'Encyclopédie des vulnérabilités'
        },
        title_short:{
          english:'Vulnerabilities',
          french:'Vulnérabilités'
        },
        url:'https://helix01fr.sysdream.io/cve?hasVendor=1',
        target:'vulns',
        icon:'clopedie.png',
      },
      cert_publications:{
        title_long:{
          english:'Security notices & alerts',
          french:'Avis et alertes de sécurité'
        },
        title_short:{
          english:'Security notices & alerts',
          french:'Avis et alertes de sécurité'
        },
        url:'https://helix01fr.sysdream.io/publication',
        target:'publication',
        icon:'publication.png',
      },
      blog:{
        title_long:{
          english:'Our analysts publications',
          french:'Les publications de nos analystes'
        },
        title_short:{
          english:'Our analysts publications',
          french:'Les publications de nos analystes'
        },
        url:'https://sysdream.com/propos/blog/',
        target:'blog',
        icon:'blog.png'
      },
    },
  },
  ressources:{
    title_long:{
      english:'Resources',
      french:'Ressources'
    },
    title_short:{
      english:'Resources',
      french:'Ressources'
    },
    icon:'clopedie.png',
    submenu:{
      passi:{
        title_long:{
          english:'ANSSI / PASSI Qualification',
          french:'Qualification ANSSI / PASSI'
        },
        url:'https://cyber.gouv.fr/produits-services-qualifies/sysdream',
        target:'sysdream',
        icon:'anssi.svg',
      },
      pdis:{
        title_long:{
          english:'ANSSI / PDIS Qualification',
          french:'Qualification ANSSI / PDIS'
        },
        url:'https://cyber.gouv.fr/produits-services-qualifies/hubone',
        target:'sysdream',
        icon:'anssi.svg',
      },
      csirt:{
        title_long:{
          english:'TF-CSIRT',
          french:'TF-CSIRT'
        },
        url:'https://www.trusted-introducer.org/directory/teams/cert-sysdream-fr.html',
        target:'sysdream',
        icon:'tf_csirt.png',
      },
      intercert:{
        title_long:{
          english:'InterCERT France',
          french:'InterCERT France'
        },
        url:'https://www.intercert-france.fr/membres/',
        target:'sysdream',
        icon:'intercert_ico.png',
      },
      enisa:{
        title_long:{
          english:'ENISA',
          french:'ENISA'
        },
        url:'https://www.enisa.europa.eu/topics/incident-response/csirt-inventory/certs-by-country-interactive-map',
        target:'sysdream',
        icon:'enisa_ico.ico',
      },
    }
  },
  myspace:{
    grants:['authenticated'],
    float:'right',
    title_long:{
      english:'My space',
      french:'Mon espace'
    },
    title_short:{
      english:'My space',
      french:'Mon espace'
    },
    icon:'monespace.png',
    url:'/private',
  }
}
